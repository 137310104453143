
export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('name', '计费模版名称', 140).setSpan({}),
        BaseTableColumn.initData('computeFeeTypeDesc', '计费方式', 120).setSpan({}),
        BaseTableColumn.initData('createTime', '操作时间', 140).setSpan({}),
        BaseTableColumn.initData('createUser', '创建人', 100).setSpan({}),
        BaseTableColumn.initData('setting', '操作').setWidth(100).setAlign('center').setButtons([
          { title: '模版详情', key: 'issueModel', permission: 'system:admin:template:issueComputeFeeTemplate' },
          { title: '编辑模板', key: 'addModel', permission: ['system:admin:template:addComputeFee', 'system:admin:template:deleteComputeFee'] },
          { title: '删除模板', key: 'del', permission: 'system:admin:template:deleteComputeFee' }
        ], ({ key, title }, row, index) => {
          if (key === 'issueModel' || key === 'addModel') {
            this.requestDetail(row.templateId, key);
          } else {
            this.$Modal.confirm({
              title: '操作成功',
              content: `<p>是否确认删除模板？</p>
                        <p>删除后并不影响已下发的设置。</p>`,
              onOk: () => {
                this.requestDel(row.templateId);
              }
            });
          }
        })
      ]
    };
  }
};
